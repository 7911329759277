exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contests-contentful-contest-slug-tsx": () => import("./../../../src/pages/contests/{ContentfulContest.slug}.tsx" /* webpackChunkName: "component---src-pages-contests-contentful-contest-slug-tsx" */),
  "component---src-pages-contests-tsx": () => import("./../../../src/pages/contests.tsx" /* webpackChunkName: "component---src-pages-contests-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-contentful-news-article-slug-tsx": () => import("./../../../src/pages/news/{ContentfulNewsArticle.slug}.tsx" /* webpackChunkName: "component---src-pages-news-contentful-news-article-slug-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-our-children-contentful-child-slug-tsx": () => import("./../../../src/pages/our-children/{ContentfulChild.slug}.tsx" /* webpackChunkName: "component---src-pages-our-children-contentful-child-slug-tsx" */),
  "component---src-pages-our-children-tsx": () => import("./../../../src/pages/our-children.tsx" /* webpackChunkName: "component---src-pages-our-children-tsx" */),
  "component---src-pages-schools-contentful-school-slug-tsx": () => import("./../../../src/pages/schools/{ContentfulSchool.slug}.tsx" /* webpackChunkName: "component---src-pages-schools-contentful-school-slug-tsx" */),
  "component---src-pages-schools-tsx": () => import("./../../../src/pages/schools.tsx" /* webpackChunkName: "component---src-pages-schools-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */)
}

